.url-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 25px;
}

.site-title {
    font-size: x-large;
    font-weight: 700;
}

.source-url {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 30px;
}
