@use 'sass:color';
@use 'mgt-react';
@use 'variables' as var;

body,
.App {
    background-color: #ddd;
}

// Utilities
button.no-button,
input[type='submit'].no-button,
input[type='reset'].no-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-align: inherit;

    // &:focus {
    //   outline: 1px solid black;
    // }

    &[disabled],
    &:disabled {
        cursor: unset;
        opacity: 0.65;
    }
}

.flex-filler {
    flex: 1 1 auto;
}

// Layout
.header {
    background-color: var.$primary;
    color: white;
    padding: 0 50px;
    border: 0;
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
}

// Whole Page body
.content {
    width: 100%;
    padding: 0 30px;
    color: var.$color;
    background-color: white;
}

// Wizard page styles
.question-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: 1rem;
    margin-top: 2rem;
    overflow-y: hidden;
}
.question-text-inner {
    padding-bottom: 20px;
    font-size: x-large;
}
.question-body {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.welcome-text {
    white-space: pre-line;
}

.text-center {
    text-align: center;
}

.scrollable {
    overflow-y: scroll;
}
