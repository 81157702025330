@use 'sass:color';

$nav-button-size: 48px;
$nav-button-padding: 4px;

.wizard-container {
    display: flex;
    position: relative;

    align-items: stretch;

    & > div {
        flex: 1;
    }

    .wizard-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .wizard-content {
        height: 500px;
        display: flex;
        flex-direction: column;
        position: relative;

        padding-bottom: 30px;

        .footer-info {
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
        }
    }

    .wizard-body {
        display: flex;
        width: 100%;
        flex: 1 1 auto;
        flex-direction: column;
        align-self: stretch;
    }

    .wizard-prev,
    .wizard-next {
        min-width: $nav-button-size;
    }

    .navbtn {
        height: $nav-button-size;
        width: $nav-button-size;

        i {
            font-size: $nav-button-size - $nav-button-padding;
        }

        &.is-disabled {
            background-color: inherit;
        }
    }

    .wizard-spinner {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: color.adjust(gray, $alpha: -0.6);
    }
}
