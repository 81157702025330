.list-item {
    color: gray;
    display: block;
    width: 100%;
    padding: 0.5rem !important; // overriding .no-button style

    &:not([disabled]):hover {
        background-color: #eee;
    }

    h2 {
        margin-top: 0;
    }
}

.clickable {
    cursor: pointer;
}
